import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import cp from "./../assets/images/CryptoPhages1060_alpha.png"
import cp2 from "./../assets/images/CryptoPhages6056_alpha.png"


export default function Hero() {

    const [phageImage, setPhageImage] = useState(getRandomImage())

    function getRandomImage() {
        let number = Math.floor(Math.random() * 2500)
        return `CryptoPhages${number.toString().padStart(4, '0')}_alpha.png`
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setPhageImage(getRandomImage())
        }, 1500)

        return () => clearTimeout(timer)
    }, [phageImage]);


    // Collection : Load all assets and let them float in a laboratory
    return (
        <div className="hero">
            <div className="hero__middle">
                <img className="hero__middle--phage"
                    src={`phages/${phageImage}`}
                ></img>
                <h1>Welcome to the <span>macrocosmos</span> of CryptoPhages!</h1>
                <div className="hero__lower">
                    <div className="hero__lower--btn1"><Link to="/collection" className="h3">Collection</Link></div>
                    <div className="hero__lower--btn2"><Link to="/about" className="h3">Story</Link></div>
                </div>
            </div>
        </div>
    )
}