import { useState, useEffect } from "react";
import {
    Address,
    Value,
    TransactionUnspentOutput,
    
} from "@emurgo/cardano-serialization-lib-asmjs"
let Buffer = require('buffer/').Buffer


export default function useConnect(api, walletData, updateAPI, updateWalletData) {
    const [walletEnabled, setWalletEnabled] = useState(false)
    const [priorConnection, setPriorConnection] = useState(JSON.parse(localStorage.getItem('priorCon')) || false)

    // If api is enabled 
    useEffect(() => {
        if (api) {
            loadWalletData()
        }
    }, [api])

    // Automatically enable api if already conected prior
    useEffect(() => {
        if (priorConnection) {
            makeConnection()
        }
    }, [window.nami])


    async function makeConnection() {
        getApi(() => {
            setWalletEnabled(window.cardano.nami.isEnabled())
            loadWalletData()
        })        
    }

    async function loadWalletData() {
        getChangeAddress()
        getBalance()
        getNetworkId()
        getUtxos()
    }

    async function getApi() {
        try {
            let namiapi = await window.cardano.nami.enable()
            updateAPI(namiapi)

            localStorage.setItem('priorCon', JSON.stringify(true))
            setPriorConnection(true)

        } catch (err) {
            console.log(err.info)
        }

    }

    async function getBalance() {
        try {
            const balanceCBORHex = await api.getBalance();

            const balance = Value.from_bytes(Buffer.from(balanceCBORHex, "hex")).coin().to_str();
            updateWalletData({balance: balance})
            updateWalletData({balance_ada: Math.round(balance /1000000)})

        } catch (err) {
            console.log(err)
        }
    }

    async function getNetworkId() {
        try {
            const networkId = await api.getNetworkId();
            updateWalletData({networkId: networkId})

        } catch (err) {
            console.log(err)
        }
    }

    async function getChangeAddress() {
        try {
            const raw = await api.getChangeAddress()
            const changeAddress = Address.from_bytes(Buffer.from(raw, "hex")).to_bech32()
            updateWalletData({changeAddress: changeAddress})


        } catch (err) {
            console.log(err)
        }
    }

    async function getUtxos() {
        let Utxos = [];
        let assetsObjArray = []

        try {
            const rawUtxos = await api.getUtxos();

            for (const rawUtxo of rawUtxos) {
                const utxo = TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
                const input = utxo.input();
                const txid = Buffer.from(input.transaction_id().to_bytes(), "utf8").toString("hex");
                const txindx = input.index();
                const output = utxo.output();
                const amount = output.amount().coin().to_str(); // ADA amount in lovelace
                const multiasset = output.amount().multiasset();
                let multiAssetStr = "";

                if (multiasset) {
                    const keys = multiasset.keys() // policy Ids of thee multiasset
                    const N = keys.len();
                    // console.log(`${N} Multiassets in the UTXO`)


                    for (let i = 0; i < N; i++) {
                        const policyId = keys.get(i);
                        const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                        // console.log(`policyId: ${policyIdHex}`)
                        const assets = multiasset.get(policyId)
                        const assetNames = assets.keys();
                        const K = assetNames.len()
                        // console.log(`${K} Assets in the Multiasset`)

                        for (let j = 0; j < K; j++) {
                            const assetName = assetNames.get(j);
                            const assetNameString = Buffer.from(assetName.name(), "utf8").toString();
                            const assetNameHex = Buffer.from(assetName.name(), "utf8").toString("hex")
                            const multiassetAmt = multiasset.get_asset(policyId, assetName)
                            multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                            //console.log(`Asset Name: ${assetNameHex}`)
                            assetsObjArray.push({name: assetNameString, nameHex: assetNameHex, policy: policyIdHex, quantity: multiassetAmt.to_str()})
                        }
                    }
                }


                const obj = {
                    txid: txid,
                    txindx: txindx,
                    amount: amount,
                    str: `${txid} #${txindx} = ${amount}`,
                    multiAssetStr: multiAssetStr,
                    TransactionUnspentOutput: utxo
                }
                Utxos.push(obj);
                // console.log(`utxo: ${str}`)
            }

            updateWalletData({Utxos: Utxos})
            updateWalletData({all_assets: assetsObjArray})

        } catch (err) {
            console.log(err)
        }
    }

    return { makeConnection, loadWalletData }
}