import { useState } from "react"

export default function CardLite(props) {

    //props.id holds an array with 3 values: index0 for dna, index1 fro rna, index 2 for xna
    const [genomeImage, setGenomeImage] = useState(props.id[0])
    var cardStyle, outerStyle
    cardStyle = { background: "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )", animation: "none" }

    return (
        <div className="card" style={outerStyle}>
            <div className="card_inner">
                <div className="card_inner_image" style={cardStyle}>
                    <img className="card_inner_image--image"
                        // src={props.IpfshashAlpha}
                        src={`phages/CryptoPhages${genomeImage}_alpha.png`}>
                    </img>
                </div>


                <div>
                    <div className="genome_buttons">
                        <div className={`${"dna"}-elem`} >
                            <div className={`${"dna"}-elem-inner` } style={{cursor: "pointer", userSelect: "none"}} onClick={() => setGenomeImage(props.id[0])}>
                                <div className="title">DNA</div>
                                <div className="description"></div>
                            </div>
                        </div>

                        <div className={`${"dna"}-elem`} >
                            <div className={`${"dna"}-elem-inner`} style={{cursor: "pointer", userSelect: "none"}} onClick={() => setGenomeImage(props.id[1])}>
                                <div className="title">RNA</div>
                                <div className="description"></div>
                            </div>
                        </div>

                        <div className={`${"dna"}-elem`} >
                            <div className={`${"dna"}-elem-inner`} style={{cursor: "pointer", userSelect: "none"}} onClick={() => setGenomeImage(props.id[2])}>
                                <div className="title">XNA</div>
                                <div className="description"></div>
                            </div>
                        </div>
                    </div>

                    <h3>{props.family}</h3>
                    <p style={{ color: "#000000" }}>{props.desc}</p>

                </div>





            </div>
        </div>
    )
}