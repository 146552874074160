import Card from "./Card"
import data from "../assets/All_data.json"
import { useEffect, useState } from "react"

export default function Collection(props) {

    const [sort, setSort] = useState("id")
    const [phageArray, setPhageArray] = useState([])
    const [sortAscending, setSortAscending] = useState(1)
    const triangleUp = {
        borderStyle: "solid",
        borderWidth: "0 100px 100px 100px",
        borderColor: "transparent transparent #ffffff transparent",
        width: "20px",
        heigt: "20px"
    }

    const activeStyle = { background: "#5f43b2", color: "#fefdfd" }
    const rarityLookup = {
        "Uncommon": 0,
        "Common": 1,
        "Rare": 2,
        "Very Rare": 3,
        "Epic": 4,
        "Legendary": 5
    }

    //testing purposes
    // useEffect(() => {
    //     var phages = []

    //     for (let i = 0; i < 20; i++) {
    //         var item = data[Math.floor(Math.random() * 2500)]
    //         phages.push(item)
    //     }
    //     setPhageArray(phages)
    // }, [])

    // real case 
    useEffect(() => {
        var phages = []
        props.walletData.all_assets.map(token => {
            if (token.policy === "44497999553f0e67405ea8c3049df4d87e50132d92e548b10b2ac8a2") {
                let fullname = token.policy + token.nameHex
                let phage = data.find(o => o.PhageNameHEX === fullname);
                phages.push(phage)
                return 0
            }})
        setPhageArray(phages)

    }, [])



    // Sorting 
    phageArray.sort((a, b) => {
        if (sort === "id") {
            let ida = a.PhageName.split('#')[1]
            let idb = b.PhageName.split('#')[1]
            if (ida < idb) return -1 * sortAscending
            if (ida > idb) return 1 * sortAscending
            return 0
        }

        if (sort === "rarity") {
            if (rarityLookup[a.Rarity] < rarityLookup[b.Rarity]) return -1 * sortAscending
            if (rarityLookup[a.Rarity] > rarityLookup[b.Rarity]) return 1 * sortAscending
            return 0
        }

        if (sort === "rewards") {
            if (rarityLookup[a.Rarity] > rarityLookup[b.Rarity]) return -1 * sortAscending
            if (rarityLookup[a.Rarity] < rarityLookup[b.Rarity]) return 1 * sortAscending
            return 0
        }

        if (sort === "genome") {
            if (a.Genome > b.Genome) return -1 * sortAscending
            if (a.Genome < b.Genome) return 1 * sortAscending
            return 0
        }

    })


    // creating elements
    const cardElementsTest = phageArray.map(elem => (
        <Card {...elem} key={elem.PhageNameHEX} />
    ))


    return (
        <div className="collection margin-large">
                
                <div className="collection-sort">
                    

                    <div
                        style={sort === "id" ? activeStyle : {}}
                        onClick={() => {
                            if (sort === "id") setSortAscending(prev => prev * -1)
                            setSort("id")
                        }}
                    >ID
                        {sort === "id" && <svg xmlns="http://www.w3.org/2000/svg"
                            style={(sortAscending === -1) ? { transform: "rotate(180deg)" } : {}}
                            fill="none" width="20px" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                        </svg>}
                    </div>

                    <div
                        style={sort === "rarity" ? activeStyle : {}}
                        onClick={() => {
                            if (sort === "rarity") setSortAscending(prev => prev * -1)
                            setSort("rarity")
                        }}
                    >RARITY
                        {sort === "rarity" && <svg xmlns="http://www.w3.org/2000/svg"
                            style={(sortAscending === -1) ? { transform: "rotate(180deg)" } : {}}
                            fill="none" width="20px" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                        </svg>}
                    </div>

                    <div
                        style={sort === "genome" ? activeStyle : {}}
                        onClick={() => {
                            if (sort === "genome") setSortAscending(prev => prev * -1)
                            setSort("genome")
                        }}
                    >GENOME
                        {sort === "genome" && <svg xmlns="http://www.w3.org/2000/svg"
                            style={(sortAscending === -1) ? { transform: "rotate(180deg)" } : {}}
                            fill="none" width="20px" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                        </svg>}
                    </div>

                </div>
            <div className="collection-cards">
                {(phageArray.length === 0) &&  <p style={{color: "white"}}>No Phages found... :(</p>   }
                {cardElementsTest}
            </div>

        </div>
    )

}