import React, {useState} from "react"
const APIContext = React.createContext()

function APIContextProvider(props) {
    const [api, setApi] = useState(undefined)
    const [walletData, setWalletData] = useState({
        networkId: undefined,
        Utxos: undefined,
        CollatUtxos: undefined,
        balance: undefined,
        changeAddress: undefined,
        rewardAddress: undefined,
        usedAddress: undefined,
        balance_ada: undefined,
        all_assets: [{name: "", nameHex: "", policy: "", quantity: ""}],
    })



    function updateWalletData(newData) {
        setWalletData(prevData => (
            {...prevData, ...newData}
        ))

    }

    function updateAPI(newAPI) {
        setApi(newAPI)
    }

    return(
        <APIContext.Provider value={{api, updateAPI, walletData, updateWalletData}}>
            {props.children}
        </APIContext.Provider>
    )

}

export {APIContextProvider, APIContext}