import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Story from "./routes/Story";
import React, { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom'
import Collection from "./components/Collection";
import { APIContext } from "./apiContext";
import useConnect from "./hooks/useConnect";

import {
  Route,
  Routes
} from "react-router-dom";

function App() {
  const { api, walletData, updateAPI, updateWalletData } = useContext(APIContext)
  const { makeConnection, loadWalletData } = useConnect(api, walletData, updateAPI, updateWalletData)
  const location = useLocation()


  return (
    <div className="App">

      <Routes>
        <Route exact path="/" element={
          <>
            <Navbar makeConnection={makeConnection} walletData={walletData} path={location.pathname}/>
            <Hero />
          </>
        } />

        <Route exact path="/collection" element={
          <>
            <Navbar makeConnection={makeConnection} walletData={walletData} path={location.pathname}/>
            <Collection walletData={walletData} />
          </>
        } />

        <Route exact path="/about" element={
          <>
            <Navbar makeConnection={makeConnection} walletData={walletData} path={location.pathname}/>
            <Story />
          </>
        } />


      </Routes>
    </div>


  );
}

export default App;
