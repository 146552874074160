import CardLite from "../components/CardLite"

export default function Story() {
    return (
        <div className="story margin-large">
            <h1>The story of CryptoPhages</h1>
            <p>One million years ago, the earth was inhabited by humans. Everything evolved at faster pace, due to the global temparatures rising. Through unknown reasons, Bacteriophages advanced so heavy, that have founded their own civilisation. Observing the decling climate, they decided that they need a new space to live. So they transfered themselfes into the Cardano blockchain. Now, all humans are gone. Cardano still remains intact, due to the large amount of SPOs all over the world that used solar energy as source to power the nodes...</p>
            <div className="story-phages">
                <h1>A lot of time went by...</h1>
                <p>...and the Phages continued to evolve on the Blockchain. Now there are four distinct families and three genomes:</p>
                <div className="story-phages-pics">
                    <CardLite id={["0021", "0113", "0406"]} family="Humanophage" desc="Humanophages are the most common Phages you will encounter – they appear in various colors and are the newest evolution of all phages."/>
                    <CardLite id={["0035", "0123", "0265"]} family="Bacteriophage" desc="Bacteriophages are the last of their kind. They are strong and agile. Only a few members of this rare species are still alive, and are ready to infect!"/>
                    <CardLite id={["0075", "0257", "0041"]} family="Zombeophage" desc="Zombeophages are feared by all Phages. Their broken capsid spreads viral genomes uncontrollably and they are unstoppable! Luckily, only a few of them exist."/>
                    <CardLite id={["1902", "1402", "1352"]} family="Megaphage" desc="Megaphage chilensis – nobody knows what they are and how they live – only one thing is for sure: they are the rarest of them all!
                    g̶͖̐̀ẹ̵̛̪͓̀̈́̋ṉ̷͛̅̉̀ò̷͖̠̮̲̊̂̍͜͝m̸̫̥̀̾̈́̎̅͜e̵̮͑͘:̶͙̻̣͜͝:̵̨̢̱̠̐̎̀͊̕ ̸̨̲̰͓͛̋̌X̶͎̟̋̀Ń̷͉͑̈́͝A̵̛̹̮̎̂̕"/>
                </div>
            </div>
        </div>
    )
}