import dna from "../assets/images/icons/dna.svg"
import star from "../assets/images/icons/star.png"

export default function Card(props) {

    //src={require(`../assets/images/phages3/${props.name}`)}
    // src={`https://${props.IpfshashAlpha.split("/")[-1]}.ipfs.infura-ipfs.io/`}></img>
    //console.log(props.IpfshashAlpha)

    let iters = 0
    var cardStyle, outerStyle
    if (props.Rarity === "Uncommon") { iters = 1; cardStyle = { background: "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )", animation: "none" } }
    else if (props.Rarity === "Common") { iters = 2; cardStyle = { background: "linear-gradient( 117deg,  rgba(123,216,96,1) 39.2%, rgba(255,255,255,1) 156.2% )", animation: "none" } }
    else if (props.Rarity === "Rare") { iters = 3; cardStyle = { background: "radial-gradient( circle farthest-corner at 10% 20%,  rgba(97,186,255,1) 0%, rgba(166,239,253,1) 90.1% )", animation: "none" } }
    else if (props.Rarity === "Very Rare") { iters = 4; cardStyle = { background: "linear-gradient( 83.2deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% )" } }
    else if (props.Rarity === "Epic") { iters = 5; cardStyle = { background: "linear-gradient(to right, #f7971e, #ffd200)" } }
    else if (props.Rarity === "Legendary") { iters = 6; cardStyle = { background: "linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)" }; outerStyle = { boxShadow: "0px 0px 45px 9px rgba(216,81,237,0.65)" } }

    const starElements = Array(iters).fill().map((e, id) => (
        <img src={star} width="20px" key={Math.floor(Math.random() * 10000)}></img>
    ))

    function calculateScore(rarity, genome, family) {
        let score = 0
        const table_data = {
            "Common": { "Humanophage": 1, "Bacteriophage": 0, "Zombeophage": 0, "Megaphage": 0 },
            "Uncommon": { "Humanophage": 2, "Bacteriophage": 3, "Zombeophage": 0, "Megaphage": 0 },
            "Rare": { "Humanophage": 3, "Bacteriophage": 4, "Zombeophage": 0, "Megaphage": 0 },
            "Very Rare": { "Humanophage": 4, "Bacteriophage": 5, "Zombeophage": 6, "Megaphage": 0 },
            "Epic": { "Humanophage": 5, "Bacteriophage": 6, "Zombeophage": 7, "Megaphage": 8 },
            "Legendary": { "Humanophage": 11, "Bacteriophage": 0, "Zombeophage": 0, "Megaphage": 10 }
        }

        const multipliers = { "DNA": 1, "RNA": 1.5, "XNA": 2 }

        try {
            score = table_data[rarity][family]

        } catch (error) {
            console.log("Unknown error", error)
        }

        try {
            score *= multipliers[genome]
        } catch (error) {
            // Unique Genomes
            score *= 3.5
        }

        return score.toString()
    }



    return (
        <div className="card" style={outerStyle}>
            <div className="card_inner">
                <div className="card_inner_image" style={cardStyle}>
                    <img className="card_inner_image--image" 
                        // src={props.IpfshashAlpha}
                        src={`phages/CryptoPhages${props.PhageName.split('#')[1]}_alpha.png`}>
                    </img>
                </div>

                <div className="card_inner--info">
                    <div className="card_inner--info-tab">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path  strokeLinejoin="round" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                        </svg>
                        <p>{calculateScore(props.Rarity, props.Genome, props.Family)}</p>
                    </div>

                    <div className="card_inner--info-tab">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path  strokeLinejoin="round" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                        </svg>
                        <p>{props.PhageName.split('#')[1]}</p>
                    </div>

                </div>
                <div className="card_inner-desc">
                    <div className={`${"dna"}-elem`} data-description="Only so I don't miss out on my friends' milestones 🤷">
                        <div className={`${"dna"}-elem-inner`}>
                            <div className="title">{props.Genome}</div>
                            <div className="description"></div>
                        </div>
                    </div>

                    <div className={`${"dna"}-elem`} data-description="Only so I don't miss out on my friends' milestones 🤷">
                        <div className={`${"dna"}-elem-inner`}>
                            <div className="title">{props.Family[0]}+</div>
                            <div className="description">{props.Family}</div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="card_inner-tags">
                    {props.Tags.replace(/['"]+/g, '').replaceAll(",", ", ")}
                </div>

                <div className="card_inner-rarity">

                    {starElements}
                </div>

            </div>
        </div>
    )
}