import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './App';
import {APIContextProvider} from "./apiContext"

import {
  BrowserRouter,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar';

const root = ReactDOM.createRoot(
  document.getElementById('root')
)

root.render(
  <BrowserRouter>

    <APIContextProvider>

      <App />

    </APIContextProvider>
  </BrowserRouter>
);




